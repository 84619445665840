import React from "react";

// styling
import "./ConfirmPresence.scss";

// components
import StaticIconsLinks from "../../../components/staticIconsLinks/StaticIconsLinks";
import InfluencerForm from "./InfluencerForm/InfluencerForm";

const ConfirmPresenceInfluencer = () => {
    return (
        <div>
            <div id="confirm-presence">
                <InfluencerForm />
            </div>
            <StaticIconsLinks />
        </div>
    );
};

export default ConfirmPresenceInfluencer;
