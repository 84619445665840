import React from "react";
//styles
import "./StaticIconsLinks.scss";
// components
import {
   FaFacebookF as FacebookIcon,
   // FaTwitter as TwitterIcon,
   FaInstagram as InstagramIcon,
   FaTwitter as TwitterIcon,
   FaTelegram as TelegramIcon,
   FaYoutube as YoutubeIcon,
} from "react-icons/fa";
import { Container } from "react-bootstrap";

const StaticIconsLinks = () => {
   return (
      <Container className="content-container-col">
         <div id="social-media-links-wrapper" className="d-none d-lg-flex social-media-links-wrapper align-items-center">
            <div className="social-media-links d-flex align-items-center justify-content-between">
               <a href="https://www.facebook.com/cryptodataus" target="_blank" rel="noreferrer">
                  <FacebookIcon className="static-icons" />
               </a>
               <a href="https://www.instagram.com/cryptodata.us" target="_blank" rel="noreferrer">
                  <InstagramIcon className="static-icons" />
               </a>
               <a href="https://twitter.com/CryptoDATA_Tech " target="_blank" rel="noreferrer">
                  <TwitterIcon className="static-icons" />
               </a>
               <a href="https://t.me/joinchat/SbIUe1WNLavdynhT " target="_blank" rel="noreferrer">
                  <TelegramIcon className="static-icons" />
               </a>
               <a href="https://www.youtube.com/c/CryptoDATAOfficial " target="_blank" rel="noreferrer">
                  <YoutubeIcon className="static-icons" />
               </a>
            </div>
         </div>
      </Container>
   );
};

export default StaticIconsLinks;
