import React from "react";

// styling
import "./ConfirmPresence.scss";

// components
import StaticIconsLinks from "../../../components/staticIconsLinks/StaticIconsLinks";
import ConfirmPresenceForm from "./ConfirmPresenceForm/ConfirmPresenceForm";

const ConfirmPresence = () => {
    return (
        <div>
            <div id="confirm-presence">
                <ConfirmPresenceForm />
            </div>
            <StaticIconsLinks />
        </div>
    );
};

export default ConfirmPresence;
