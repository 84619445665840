export const influencersList = [
   "cryptobrowser",
   "cointurk",
   "usethebitcoin-(utb)",
   "bitcoins-in-ireland",
   "cryptoexplorer",
   "cryptostatistics",
   "tps",
   "issuewire",
   "cryptokielas",
   "mainetrend",
   "kyla-rabaño",
   "megababki",
   "ilme-aalim",
   "rey-santos-crypto",
   "stefano-castiello",
   "diariobitcoin",
   "criptomonedas-eu",
   "tg-wallstreet",
   "tg-cryptojournal",
   "mario-fresh",
   "radu-tibulca",
   "custom-tuning",
   "bestrallychallenge",
   "andy-popescu",
   "mihai-morar",
   "andrei-niculae",
   "ana-baniciu",
   "vlad-dragulin",
   "andrei-stoica",
   "cazacu",
   "elias-thiess",
   "emma-stefan",
   "cdt-sm",
   "boe",
   "alex-montrey",
];
