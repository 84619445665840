import React, { useState, useEffect } from 'react';
import './InvitationSent.scss';
import ParticleEffectButton from 'react-particle-effect-button';

import Checks from '../../../../assets/Dubai/checks.svg';

const InvitationSent = () => {
  const [hiddenParticles, setHiddenParticles] = useState(() => false);

  const animation = () => setHiddenParticles(!hiddenParticles);

  // on mount scroll to top of the page
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div className="w-100 buy-edain d-flex justify-content-center align-items-center">
      <div className="buy-edain-container blur-bg d-flex flex-column text-white mx-2">
        <h5 className="text-center mt-5 font-size-40 mb-0 exo-bold">
          Your invitation has
        </h5>
        <h5 className="text-center mb-0 font-size-40 exo-bold">
          been sent to your e-mail.
        </h5>
        <div className="w-100 d-flex align-items-center justify-content-center my-3" />

        <img src={Checks} className="img-fluid mw-180 mx-auto" alt="" />

        <h5 className="color-neon-green mb-0 exo-bold mx-auto text-center mt-5 mb-4">
          Welcome to the Decentralized
          <br /> Connectivity Redefined
        </h5>

        <div className="w-100 d-flex justify-content-center mt-3  mb-5">
          <ParticleEffectButton
            color="#000000"
            hidden={hiddenParticles}
            onComplete={() => window.location.href = "https://cryptodata.com/DCR2022"}
          >
            <div
              className="particle-effect-btn-div mr-0 font-size-20 exo-regular w-auto"
              onClick={animation}
            >
              Go to website
            </div>
          </ParticleEffectButton>
        </div>

      </div>
    </div>
  );
};

export default InvitationSent;
