import "./App.scss";
import { Switch, Route } from "react-router-dom";

import ConfirmPresence from './views/BucharestEvent/ConfirmPresence/ConfirmPresence';
import ConfirmPresenceVip from "./views/BucharestEvent/ConfirmPresence/ConfirmPresenceVip";
import ConfirmPresenceInfluencer from "./views/BucharestEvent/ConfirmPresence/ConfirmPresenceInfluencer";
import ConfirmPresenceWithoutInfluencer from "./views/BucharestEvent/ConfirmPresence/ConfirmPresenceWithoutInfluencer";
import ScrollToTop from "./components/ScrollToTop";
import Error404 from "./views/Error404/Error404";

const App = () => {
   return (
      <main className="App">
         <ScrollToTop>
            <Switch>
               <Route exact path="/" component={ConfirmPresence} />
               <Route path="/:id" exact component={ConfirmPresenceVip} />
               <Route path="/giveaway/:eventType/:influencer" exact component={ConfirmPresenceInfluencer} />
               <Route path="/giveaway/:eventType" exact component={ConfirmPresenceWithoutInfluencer} />
               <Route path="*" exact component={Error404} />
            </Switch>
         </ScrollToTop>
      </main>
   );
};

export default App;
