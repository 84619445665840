import React from "react";

// styling
import "./ConfirmPresence.scss";

// components
import StaticIconsLinks from "../../../components/staticIconsLinks/StaticIconsLinks";
import VipForm from "./VipForm/VipForm";

const ConfirmPresence = () => {
    return (
        <div>
            <div id="confirm-presence">
                <VipForm />
            </div>
            <StaticIconsLinks />
        </div>
    );
};

export default ConfirmPresence;
