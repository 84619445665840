import React, { useState, useEffect } from 'react';

// styling
import './ConfirmPresenceForm.scss';

// components
import InvitationSent from '../InvitationSent/InvitationSent';

// utils
// import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import axios from 'axios';
// import Background from '../../../../assets/Dubai/presence-modal-bg.png';

const ConfirmPresenceForm = () => {
    const [id, setId] = useState(() => '');

    // form state
    const [nameInput, setNameInput] = useState(() => '');
    const [emailInput, setEmailInput] = useState(() => '');
    const [emailNotValid, setEmailNotValid] = useState(() => false);
    const [surnameInput, setSurnameInput] = useState(() => '');
    const [companyInput, setCompanyInput] = useState(() => '');
    const [marketingCheck, setMarketingCheck] = useState(() => false);

    // display state
    const [confirmed, setConfirmed] = useState(() => false);

    const accTypes = [
        {
            type: 'press',
            uuid: 'fde675fa-2145-4097-9e37-016167801179'
        },
        {
            type: 'open',
            uuid: '69233eb1-91d7-4512-afd0-fadfb91eec2e'
        }
    ];

    // on mount scroll to top of the page
    useEffect(() => {
        window.scrollTo(0, 0)

        // set default id to open
        setId('69233eb1-91d7-4512-afd0-fadfb91eec2e');
    }, []);

    // empty input error
    let errorInputEmpty = 'This field can not be empty!';

    // input handlers
    const handleName = event => setNameInput(event.target.value);
    const handleSurname = event => setSurnameInput(event.target.value);
    const handleEmail = event => setEmailInput(event.target.value);
    const handleCompany = event => setCompanyInput(event.target.value);
    const handleMarketingCheck = event => setMarketingCheck(event.target.checked);

    // remove input errors
    const removeNameError = () => {
        if (nameInput === errorInputEmpty) return setNameInput('');
    };

    const removeSurnameError = () => {
        if (surnameInput === errorInputEmpty) return setSurnameInput('');
    };

    const removeEmailError = () => {
        if (emailInput === errorInputEmpty) return setEmailInput('');
        if (emailNotValid) return setEmailNotValid(false);
    };

    const removeCompanyError = () => {
        if (companyInput === errorInputEmpty) return setCompanyInput('');
    };

    // validate email function
    const validateEmail = email => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const updateId = (value) => {
        setId(value);
    }

    const handleSend = async () => {
        if (
            (id === accTypes[0].uuid && (
                nameInput === '' ||
                emailInput === '' ||
                surnameInput === '' ||
                companyInput === '' ||

                nameInput === errorInputEmpty ||
                emailInput === errorInputEmpty ||
                surnameInput === errorInputEmpty ||
                companyInput === errorInputEmpty
            )) || (
                id === accTypes[1].uuid && (
                    nameInput === '' ||
                    emailInput === '' ||
                    surnameInput === '' ||

                    nameInput === errorInputEmpty ||
                    emailInput === errorInputEmpty ||
                    surnameInput === errorInputEmpty
                )
            )) {
            if (nameInput === '') {
                setNameInput(errorInputEmpty);
            }
            if (surnameInput === '') {
                setSurnameInput(errorInputEmpty);
            }
            if (emailInput === '') {
                setEmailInput(errorInputEmpty);
            }
            if (id === accTypes[0].uuid && companyInput === '') {
                setCompanyInput(errorInputEmpty);
            }
            return;
        } else if (!validateEmail(emailInput)) {
            setEmailNotValid(true);
            return cogoToast.error('Email is not valid');
        } else if (!marketingCheck) {
            return cogoToast.error('Please check the Privacy Notice agreement!');
        } else {
            try {
                const obj = {
                    name: nameInput,
                    surname: surnameInput,
                    email: emailInput,
                    company: "",
                    phone: "",
                    invitedBy: "",
                };
                console.log(obj);
                const confirmReq = await axios.post(
                    `https://jupiter-test.com/api/invitation/${id}`,
                    obj
                );
                if (confirmReq.status === 200) {
                    setConfirmed(true);
                }
            } catch (err) {
                if (err.response) {
                    switch (err.response.status) {
                        case 400:
                            cogoToast.error('Invitation code does not exist.');
                            return;
                        case 406:
                            cogoToast.error("Invitation code has already been used.")
                            return;
                        case 409:
                            cogoToast.error("This email has already been used.");
                            return;
                        case 422:
                            cogoToast.error(err.response.data.validation.body.message);
                            return;
                        default:
                            return cogoToast.error(err.response.data.errors.message);
                    }
                }
            }
        }
    };

    let toRender;

    if (!confirmed) {
        toRender = (
            <React.Fragment>
                <div className="w-100 buy-edain confirm-presence-form d-flex flex-column justify-content-center align-items-center">
                    <div className="custom-pb">
                        <div className="w-100 text-center exo-extraBold font-size-48 custom-line-height mb-3" style={{ color: '#bd9262' }}>
                            Event registration
                        </div>
                        <div className="text-center font-size-18 white-color openSans-regular">
                            We are pleased to invite you to the Decentralized Connectivity Redefined event.
                            Fill the form below in order to confirm your attendance.
                        </div>
                    </div>

                    <div className="buy-edain-container blur-bg d-flex flex-column text-white mx-2">
                        <h5 className="text-center font-size-24 exo-bold">
                            Please fill in your personal details
                        </h5>
                        <div id="confirm-presence-form" className="">
                            <div className="mt-3 d-flex flex-wrap gap-1">
                                <div className="radio-button-div">
                                    <input
                                        type="radio"
                                        onChange={e => updateId(e.currentTarget.value)}
                                        checked={id === accTypes[1].uuid}
                                        value={accTypes[1].uuid}
                                        name="radio"
                                        id={`radio-1`}
                                    />
                                    <label className="radio-button font-size-18 exo-medium" htmlFor="radio-1">
                                        Open Invitation
                                    </label>
                                </div>
                                <div className="radio-button-div">
                                    <input
                                        type="radio"
                                        onChange={e => updateId(e.currentTarget.value)}
                                        checked={id === accTypes[0].uuid}
                                        value={accTypes[0].uuid}
                                        name="radio"
                                        id={`radio-2`}
                                    />
                                    <label className="radio-button font-size-18 exo-medium" htmlFor="radio-2">
                                        Press Invitation
                                    </label>
                                </div>
                            </div>
                            <div className="mt-2 d-flex flex-column">
                                <label className="color-light-grey font-size-16 exo-regular">
                                    {'Name'}
                                </label>
                                <input
                                    type="text"
                                    value={nameInput}
                                    placeholder={'Your name'}
                                    className={`pl-2 details-input font-size-18 exo-regular ${nameInput === errorInputEmpty ? 'error-border error-text' : 'no-border normal-text'}`}
                                    onChange={handleName}
                                    onFocus={removeNameError}
                                />
                            </div>

                            <div className="mt-2 d-flex flex-column">
                                <label className="color-light-grey font-size-16 exo-regular">
                                    {'Surname'}
                                </label>
                                <input
                                    type="text"
                                    value={surnameInput}
                                    placeholder={'Your name'}
                                    className={`pl-2 details-input font-size-18 exo-regular ${surnameInput === errorInputEmpty ? 'error-border error-text' : 'no-border normal-text'}`}
                                    onChange={handleSurname}
                                    onFocus={removeSurnameError}
                                />
                            </div>

                            <div className="mt-2 d-flex flex-column">
                                <label className="color-light-grey font-size-16 exo-regular">
                                    {'Email'}
                                </label>
                                <input
                                    type="email"
                                    value={emailInput}
                                    placeholder="Your email"
                                    className={`pl-2 details-input font-size-18 exo-regular ${emailInput === errorInputEmpty || emailNotValid ? 'error-border error-text' : 'no-border normal-text'}`}
                                    onChange={handleEmail}
                                    onFocus={removeEmailError}
                                />
                            </div>

                            {id === accTypes[0].uuid && (
                                <div className="mt-2 d-flex flex-column">
                                    <label className="color-light-grey font-size-16 exo-regular">
                                        {'Company'}
                                    </label>
                                    <input
                                        type="text"
                                        value={companyInput}
                                        placeholder={'Your company'}
                                        className={`pl-2 details-input font-size-18 exo-regular ${companyInput === errorInputEmpty ? 'error-border error-text' : 'no-border normal-text'}`}
                                        onChange={handleCompany}
                                        onFocus={removeCompanyError}
                                    />
                                </div>
                            )}

                            <div className="w-100 mt-4 d-flex justify-content-center">
                                <label className="terms-label">
                                    <input
                                        type="checkbox"
                                        checked={marketingCheck}
                                        className="mr-2 check-input"
                                        onChange={handleMarketingCheck}
                                    />
                                    <span className="checkmark"></span>
                                    <span className="font-size-16">
                                        By ticking this box you accept to receive
                                        updates and marketing materials, and accept the website's
                                    </span>
                                    <a href="https://cryptodata.com/privacy-policy" target="_blank" rel="noreferrer" className="ml-1 terms-link font-size-16">
                                        Privacy Policy
                                    </a>
                                </label>
                            </div>
                        </div>

                        <div className="w-100 d-flex justify-content-center mt-4 ">
                            <div
                                className="particle-effect-btn-div mr-0 font-size-20 exo-regular w-auto"
                                onClick={handleSend}
                            >
                                Confirm attendance
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    } else {
        toRender = <InvitationSent />;
    }

    return (
        <div className="content-container-col flex-column">
            <div className="overlay-bck"></div>
            {/* <img src={Background} alt="presence-bg" className="event-background" /> */}
            {toRender}
        </div>
    );
};

export default ConfirmPresenceForm;
