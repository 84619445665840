import React from "react";

// styling
import "./ConfirmPresence.scss";

// components
import StaticIconsLinks from "../../../components/staticIconsLinks/StaticIconsLinks";
import WithoutInfluencerForm from "./WithoutInfluencerForm/WithoutInfluencerForm";

const ConfirmPresenceWithoutInfluencer = () => {
    return (
        <div>
            <div id="confirm-presence">
                <WithoutInfluencerForm />
            </div>
            <StaticIconsLinks />
        </div>
    );
};

export default ConfirmPresenceWithoutInfluencer;
