import React from 'react';
import './Error404.scss';
import ErrorImage from '../../assets/Error/404-error-v1.webp';

const Error404 = () => {
    return (
        <div className="error-404">
            <div className="container-fluid container-fluid-cryptodata">
                <div className="absolute-container">
                    <div className="error-paragraphs d-flex flex-column align-items-center text-center">
                        <p className="font-size-48 font-gotham-medium white-color mb-0">The page you're looking</p>
                        <p className="font-size-48 font-gotham-medium white-color mb-0">for can't be found.</p>
                    </div>
                    <div className="img-404-container">
                        <img className="img-fluid" src={ErrorImage} alt="bg-error" />
                    </div>
                    <div className="error-buttons font-size-20 d-flex align-items-center">
                        <div className="homepage-button-container">
                            <a class="homepage-button btn btn-outline-primary font-size-20 mt-3 " href="https://cryptodata.com">Go to homepage</a>
                        </div>
                        <a class="btn btn-primary cryptodata-btn mt-3 " href="https://cryptodata.com/shop">Shop now</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Error404;