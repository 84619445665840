import React, { useState, useEffect } from 'react';

// styling
import '../ConfirmPresenceForm/ConfirmPresenceForm.scss';

// components
import InvitationSent from '../InvitationSent/InvitationSent';

// utils
// import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const VipForm = () => {
    // form state
    const [nameInput, setNameInput] = useState(() => '');
    const [surnameInput, setSurnameInput] = useState(() => '');
    const [emailInput, setEmailInput] = useState(() => '');
    const [emailNotValid, setEmailNotValid] = useState(() => false);
    const [phoneInput, setPhoneInput] = useState(() => '');
    const [companyInput, setCompanyInput] = useState(() => '');
    const [invitedByInput, setInvitedByInput] = useState(() => '');
    const [marketingCheck, setMarketingCheck] = useState(() => false);
    const params = useParams();

    // display state
    const [confirmed, setConfirmed] = useState(() => false);

    // on mount scroll to top of the page
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    // empty input error
    let errorInputEmpty = 'This field can not be empty!';

    // input handlers
    const handleName = event => setNameInput(event.target.value);
    const handleSurname = event => setSurnameInput(event.target.value);
    const handleEmail = event => setEmailInput(event.target.value);
    const handlePhone = event => setPhoneInput(event.target.value);
    const handleCompany = event => setCompanyInput(event.target.value);
    const handleInvitedBy = event => setInvitedByInput(event.target.value);
    const handleMarketingCheck = event => setMarketingCheck(event.target.checked);

    // remove input errors
    const removeNameError = () => {
        if (nameInput === errorInputEmpty) return setNameInput('');
    };

    const removeSurnameError = () => {
        if (surnameInput === errorInputEmpty) return setSurnameInput('');
    };

    const removeEmailError = () => {
        if (emailInput === errorInputEmpty) return setEmailInput('');
        if (emailNotValid) return setEmailNotValid(false);
    };

    const removePhoneError = () => {
        if (phoneInput === '0000000000') return setPhoneInput('');
    };

    const removeCompanyError = () => {
        if (companyInput === errorInputEmpty) return setCompanyInput('');
    };

    const removeInvitedByError = () => {
        if (invitedByInput === errorInputEmpty) return setInvitedByInput('');
    };

    // validate email function
    const validateEmail = email => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSend = async () => {
        if (
            nameInput === '' ||
            surnameInput === '' ||
            emailInput === '' ||
            phoneInput === '' ||
            companyInput === '' ||
            invitedByInput === '' ||

            nameInput === errorInputEmpty ||
            surnameInput === errorInputEmpty ||
            emailInput === errorInputEmpty ||
            phoneInput === '0000000000' ||
            companyInput === errorInputEmpty ||
            invitedByInput === errorInputEmpty
        ) {
            if (nameInput === '') {
                setNameInput(errorInputEmpty);
            }
            if (surnameInput === '') {
                setSurnameInput(errorInputEmpty);
            }
            if (emailInput === '') {
                setEmailInput(errorInputEmpty);
            }
            if (phoneInput === '') {
                setPhoneInput('0000000000');
            }
            if (invitedByInput === '') {
                setInvitedByInput(errorInputEmpty);
            }
            if (companyInput === '') {
                setCompanyInput(errorInputEmpty);
            }
            return;
        } else if (!validateEmail(emailInput)) {
            setEmailNotValid(true);
            return cogoToast.error('Email is not valid');
        } else if (!marketingCheck) {
            return cogoToast.error('Please check the Privacy Notice agreement!');
        } else {
            try {
                const obj = {
                    name: nameInput,
                    surname: surnameInput,
                    email: emailInput,
                    company: companyInput,
                    phone: phoneInput,
                    invitedBy: invitedByInput,
                };

                const confirmReq = await axios.post(
                    `https://jupiter-test.com/api/invitation/${params.id}`,
                    obj
                );
                if (confirmReq.status === 200) {
                    setConfirmed(true);
                }
            } catch (err) {
                console.log(err.response.data)
                if (err.response) {
                    switch (err.response.status) {
                        case 400:
                            cogoToast.error('Invitation code does not exist.');
                            return;
                        case 403:
                            cogoToast.error('Invitation code does not exist.')
                            return;
                        case 406:
                            cogoToast.error("Invitation code has already been used.")
                            return;
                        case 409:
                            cogoToast.error("This email has already been used.");
                            return;
                        case 422:
                            cogoToast.error(err.response.data.validation.body.message);
                            return;
                        default:
                            return cogoToast.error(err.response.data.errors.message);
                    }
                }
            }
        }
    };

    let toRender;

    if (!confirmed) {
        toRender = (
            <React.Fragment>
                <div className="w-100 buy-edain confirm-presence-form d-flex flex-column justify-content-center align-items-center">
                    <div className="custom-pb">
                        <div className="w-100 text-center exo-extraBold font-size-48 white-color custom-line-height mb-3" style={{ color: '#bd9262' }}>
                            Event registration
                        </div>
                        <div className="text-center font-size-18 white-color openSans-regular">
                            We are pleased to invite you to the Decentralized Connectivity Redefined event.
                            Fill the form below in order to confirm your attendance.
                        </div>
                    </div>

                    <div className="buy-edain-container blur-bg d-flex flex-column text-white mx-2">
                        <h5 className="text-center font-size-24 exo-bold">
                            Please fill in your personal details
                        </h5>
                        <div id="confirm-presence-form" className="">
                            <div className="mt-2 d-flex flex-column">
                                <label className="color-light-grey font-size-16 exo-regular">
                                    {'Name'}
                                </label>
                                <input
                                    type="text"
                                    value={nameInput}
                                    placeholder={'Your name'}
                                    className={`pl-2 details-input font-size-18 exo-regular ${nameInput === errorInputEmpty ? 'error-border error-text' : 'no-border normal-text'}`}
                                    onChange={handleName}
                                    onFocus={removeNameError}
                                />
                            </div>

                            <div className="mt-2 d-flex flex-column">
                                <label className="color-light-grey font-size-16 exo-regular">
                                    {'Surname'}
                                </label>
                                <input
                                    type="text"
                                    value={surnameInput}
                                    placeholder={'Your name'}
                                    className={`pl-2 details-input font-size-18 exo-regular ${surnameInput === errorInputEmpty ? 'error-border error-text' : 'no-border normal-text'}`}
                                    onChange={handleSurname}
                                    onFocus={removeSurnameError}
                                />
                            </div>

                            <div className="mt-2 d-flex flex-column">
                                <label className="color-light-grey font-size-16 exo-regular">
                                    {'Email'}
                                </label>
                                <input
                                    type="email"
                                    value={emailInput}
                                    placeholder="Your email"
                                    className={`pl-2 details-input font-size-18 exo-regular ${emailInput === errorInputEmpty || emailNotValid ? 'error-border error-text' : 'no-border normal-text'}`}
                                    onChange={handleEmail}
                                    onFocus={removeEmailError}
                                />
                            </div>

                            <div className="mt-2 d-flex flex-column">
                                <label className="color-light-grey font-size-16 exo-regular">
                                    {'Phone'}
                                </label>
                                <input
                                    type="number"
                                    value={phoneInput}
                                    placeholder={'Your phone number'}
                                    className={`pl-2 details-input font-size-18 exo-regular ${phoneInput === '0000000000' ? 'error-border error-text' : 'no-border normal-text'}`}
                                    onChange={handlePhone}
                                    onFocus={removePhoneError}
                                />
                            </div>

                            <div className="mt-2 d-flex flex-column">
                                <label className="color-light-grey font-size-16 exo-regular">
                                    {'Company'}
                                </label>
                                <input
                                    type="text"
                                    value={companyInput}
                                    placeholder={'Your company'}
                                    className={`pl-2 details-input font-size-18 exo-regular ${companyInput === errorInputEmpty ? 'error-border error-text' : 'no-border normal-text'}`}
                                    onChange={handleCompany}
                                    onFocus={removeCompanyError}
                                />
                            </div>

                            <div className="mt-2 d-flex flex-column">
                                <label className="color-light-grey font-size-16 exo-regular">
                                    {'Invited By'}
                                </label>
                                <input
                                    type="text"
                                    value={invitedByInput}
                                    placeholder={'Invited By'}
                                    className={`pl-2 details-input font-size-18 exo-regular ${invitedByInput === errorInputEmpty ? 'error-border error-text' : 'no-border normal-text'}`}
                                    onChange={handleInvitedBy}
                                    onFocus={removeInvitedByError}
                                />
                            </div>

                            <div className="w-100 mt-4 d-flex justify-content-center">
                                <label className="terms-label">
                                    <input
                                        type="checkbox"
                                        checked={marketingCheck}
                                        className="mr-2 check-input"
                                        onChange={handleMarketingCheck}
                                    />
                                    <span className="checkmark"></span>
                                    <span className="font-size-16">
                                        By ticking this box you accept to receive
                                        updates and marketing materials, and accept the website's
                                    </span>
                                    <a href="https://cryptodata.com/privacy-policy" target="_blank" rel="noreferrer" className="ml-1 terms-link font-size-16">
                                        Privacy Policy
                                    </a>
                                </label>
                            </div>
                        </div>

                        <div className="w-100 d-flex justify-content-center mt-4 ">
                            <div
                                className="particle-effect-btn-div mr-0 font-size-20 exo-regular w-auto"
                                onClick={handleSend}
                            >
                                Confirm attendance
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    } else {
        toRender = <InvitationSent />;
    }

    return (
        <div className="content-container-col flex-column">
            <div className="overlay-bck"></div>
            {toRender}
        </div>
    );
};

export default VipForm;
