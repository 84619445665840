import React, {useEffect} from 'react';

// styling
import './Error.scss';

const Error = ({ error }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className="content-container-col flex-column" id="error-container">
            <div id="error-box" className="w-100 buy-edain error-box d-flex flex-column justify-content-center align-items-center">
                <div className="buy-edain-container blur-bg d-flex flex-column text-white mx-2">
                    <h5 className="text-center font-size-24 exo-bold">
                        {error}
                    </h5>
                    <div className="w-100 d-flex justify-content-center mt-4 ">
                        <div
                            className="particle-effect-btn-div mr-0 font-size-20 exo-regular w-auto"
                            onClick={() => window.location.href = "https://cryptodata.com/DCR2022"}
                        >
                            Back to Home Page
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Error;